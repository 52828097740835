import Container from '@/components/atoms/Container'
import Label from '@/components/atoms/Label'
import {ImageType} from '@/components/types/image'

import {LinkType} from '@/components/types/link'
import Image from 'next/image'
import styles from './Facts.module.scss'
type FactsProps = {
  block: {
    title: string
    link: LinkType
    image: ImageType
    facts: [{title: string; text: string}]
  }
}

export default function Facts({block}: FactsProps) {
  const {image, title, link, facts} = block
  return (
    <>
      <Label title={title} link={link} />
      <Container>
        <div className={styles.imageFactsWrapper}>
          {image?.mediaItemUrl && (
            <div className={styles.imageWrapper}>
              <Image
                data-aos="fade-in"
                src={image.mediaItemUrl}
                alt={image.altText}
                width={image.mediaDetails.width}
                height={image.mediaDetails.height}
                layout="responsive"
                objectFit="cover"
              />
            </div>
          )}
          <div className={styles.factsWrapper}>
            <div className={styles.factsContainer}>
              {facts?.length > 0 &&
                facts.map((fact, index) => (
                  <div
                    data-aos="fade-up"
                    key={index}
                    className={styles.factWrapper}
                  >
                    <div className={styles.factTitle}>{fact.title}</div>
                    <div className={styles.factText}>{fact.text}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
