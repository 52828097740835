import {LinkType} from '@/components/types/link'
import cn from 'classnames'
import Button from '../Button'
import Container from '../Container'
import styles from './Label.module.scss'
type labelProps = {
  title: string
  link?: LinkType
  isSmall?: boolean
  removeBorders?: boolean
  hasParent?: boolean
}
export default function Label({
  title,
  link,
  isSmall,
  removeBorders,
  hasParent
}: labelProps) {
  const element = (
    <div
      className={cn(
        styles.titleWrapper,
        isSmall ? styles.isSmall : '',
        !hasParent ? styles.paddingTop : ''
      )}
    >
      <div className={cn(styles.title, isSmall ? styles.isSmall : '')}>
        {title}
      </div>
      {link?.url && (
        <Button
          className={styles.button}
          type="blue"
          text={link.title}
          url={link.url}
          urlExternal={link.target}
        />
      )}
    </div>
  )
  if (hasParent) return element
  return <Container removeBorders={removeBorders}>{element}</Container>
}
